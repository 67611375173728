<template>
	<div class="tcontainer">
		<Toast />
		<div class="clearfix ttitle">
			<div class="pull-right" style="margin: 5px;">
				<router-link to="/admin/xray-reporting">X-Ray Reporting</router-link>
			</div>
			<div class="title">
				X-Ray Overview ({{totalReported}}) &nbsp; 
				(Uptime Since: {{ format(data?.uptimeSince, "DD/MM/yy hh:mm a") }})
			</div>

			
		</div>
		<div>
			<div style="height: 30px;"></div>
			<div class="container-fluid">
				<div class="row">
					<div class="col-4 card">
						<h5 style="padding-top: 8px;">System Pool</h5>
						<div>
							<ol>
								<li v-for="v in data?.openReports" :key="v">
									<span :class="{'text-danger': v.urgent}">#{{ v.id }}</span> <span class="badge badge-info">{{ v.category }}</span>
								</li>
							</ol>
						</div>
					</div>
					<div class="col-4 card">
						<h5 style="padding-top: 8px;">Active Reports</h5>
						<div>
							<ol>
								<li v-for="v in data?.reports" :key="v">
									<div>ID: {{ v.id }}</div>
									<div>State: {{ v.state }}</div>
									<div>Doctor: #{{ v.doctorId }} {{ v.doctor }}</div>
								</li>
							</ol>
						</div>
					</div>
					<div class="col-4 card">
						<h5 style="padding-top: 8px;">Delayed Reports</h5>
						<div>
							<ol class="text-danger">
								<li v-for="v in data?.delayedReportes" :key="v">
									<div>
										#{{ v.id }} Delaly {{v.delay}}

										<div v-if="v.assign_type == 'all'" class="badge badge-success">SP</div>
										<div v-if="v.assign_type == 'specific'" class="badge badge-danger">DP</div>

									</div>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
			<div style="height: 10px;"></div>

			<div class="card" style="margin: 20px 0; padding: 20px;" v-for="doc in doctors" :key="doc">
				<h4 style="margin-bottom: 20px;">
					<span v-if="doc.online" class="text-success fa fa-circle"></span>
					#{{ doc.id }} {{doc.name}} ({{ doc.totalReported }})
				</h4>
				<div class="container-fluid">
					<div class="row">
						<div class="col-2 card">
							<b style="padding: 8px 0;">Direct Pool</b>
							<div>
								<ol>
									<li v-for="v in doc.reports" :key="v">
										#{{ v }}
									</li>
								</ol>
							</div>
						</div>
						<div class="col-2 card">
							<b style="padding: 8px 0;">Direct Urgent Pool</b>
							<div>
								<ol>
									<li v-for="v in doc.reportsUrgent" :key="v">
										#{{ v }}
									</li>
								</ol>
							</div>
						</div>
						<div class="col-2 card">
							<b style="padding: 8px 0;">Reviews Pool</b>
							<div>
								<ol>
									<li v-for="v in doc.reviews" :key="v">
										#{{ v }}
									</li>
								</ol>
							</div>
						</div>
						<div class="col-2 card">
							<b style="padding: 8px 0;">Rejected Reports</b>
							<div>
								<ol>
									<li v-for="v in doc.rejectedReports" :key="v">
										#{{ v }}
									</li>
								</ol>
							</div>
						</div>

						<div class="col-2 card">
							<b style="padding: 8px 0;">Drafted Reports</b>
							<div>
								<ol>
									<li v-for="v in doc.reportsDraft" :key="v">
										#{{ v }}
									</li>
								</ol>
							</div>
						</div>
						
						<div class="col-2 card">
							<b style="padding: 8px 0;">Sockets</b>
							<div>
								<ol>
									<li v-for="v in doc.sockets" :key="v">
										<div>
											<b>State: </b> {{ v.state }}
										</div>
										<div>
											<b>Report Id: </b> {{ v.reportId }}
										</div>
									</li>
								</ol>
							</div>
						</div>
					</div>
				</div>
				<div>
					<div v-for="i in doc.activeGraph" :key="i" :style="{width: i.width+'%', background: i.online ? 'green' : 'silver'}" style="height: 15px; float: left;"></div>
				</div>
				<div>
					<div style="display: flex; ">
						<div v-for="r in reports" :key="r" style=" flex-grow: 1; flex-shrink: 0; margin-top: auto">
							<div style="text-align: center; padding-top: 10px">
								{{ r.time }}
							</div>
						</div>
					</div>
				</div>
			</div>


			<div style="padding-top: 20px; padding-bottom: 20px; display: flex; ">
				<div v-for="r in reports" :key="r" style=" flex-grow: 1; flex-shrink: 0; margin-top: auto;">
					<div style="position: relative;">
						<div :style="{height: (r.rc*2)+'px'}" style="background: skyblue"></div>
						<div :style="{height: (r.cc*2)+'px'}" style="background: yellow; position: absolute; left: 0; bottom: 0; z-index: 2; opacity: .5; width: 100%;"></div>
					</div>
					<div style="text-align: center; padding-top: 10px; border: 1px solid skyblue;">
						<div>{{ r.rc }}</div>
						<div>{{ r.cc }}</div>
						{{ r.time }}
					</div>
				</div>
			</div>

		</div>
		
	</div>
</template>
<script>
import Toast from 'primevue/toast';
export default {
	components: {
		Toast,
	},
	data() {
		return {
			data: null,
			isSuper: false,

			totalReported: 0,

			doctors: [],

			reports: [],

		}
	},
	mounted() {
		this.loadLazyData();
	},
	methods: {
		enter() {
			let user = JSON.parse(window.localStorage.admin_access_token);
			this.isSuper = user.is_super == 'y';
		},
		async loadLazyData() {
			let d = await this.get("../rad");
			// console.log(d);
			this.data = d;



			for(let i in d.doctors){
				if(!d.doctors[i].active) continue;
				this.doctors.push(d.doctors[i])

				this.totalReported += d.doctors[i].totalReported;

				let doc = d.doctors[i];

				let dt =  new Date();
				dt.setHours(6);
				dt.setMinutes(0);
				dt.setSeconds(0);
				dt.setMilliseconds(0);

				let startTime = dt.getTime();

				dt.setDate(dt.getDate()+1);
				dt.setHours(0);

				let endTime = dt.getTime();

				let duration = endTime-startTime;
				
				let activeGraph = [];

				for(let t of doc.activeTiming){
					if(!activeGraph.length){
						if(t.start < startTime){
							if(t.end > startTime){
								let end = new Date().getTime();
								if(t.end) end = t.end;
								let p = ((end-startTime)/duration)*100;
								activeGraph.push({online: true, width: p, end });
							}
						}else{
							let p = ((t.start-startTime)/duration)*100;
							activeGraph.push({online: false, width: p, end: t.start});

							let end = new Date().getTime();
							if(t.end) end = t.end;
							p = ((end-t.start)/duration)*100;
							activeGraph.push({online: true, width: p, end });
						}
					}else{
						let lastEnd = activeGraph[activeGraph.length-1].end;

						let p = ((t.start-lastEnd)/duration)*100;
						activeGraph.push({online: false, width: p, end: t.start});

						let end = new Date().getTime();
						if(t.end) end = t.end;
						p = ((end-t.start)/duration)*100;
						activeGraph.push({online: true, width: p, end });
					}
				}
				
				
				let lastEnd = startTime;
				if(activeGraph.length) lastEnd = activeGraph[activeGraph.length-1].end;

				let p = ((new Date().getTime()-lastEnd)/duration)*100;
				activeGraph.push({online: false, width: p, end: new Date().getTime()});

				doc.activeGraph = activeGraph;

			}

			for(let r of d.reports){
				if(r.doctorId){
					let doc = this.doctors.find(d=>d.id == r.doctorId)

					if(doc){
						r.doctor = doc.name;
					}
				}
			}

			let reports = {};

			for(let r of d.todayReports){
				let t = this.format(r.created_at, "hh a")
				if(t in reports){
					reports[t].count++;
					if(!(reports[t].centers.includes(r.center_id))) reports[t].centers.push(r.center_id);
				}else reports[t] = {
					count: 1,
					centers: [r.center_id],
				};
			}
			let t = new Date();
			t.setHours(6);

			while(t.getHours()){
				let h = this.format(t, "hh a")
				t.setHours(t.getHours()+1);
				let rc = 0;
				let cc = 0;
				if(h in reports){
					rc = reports[h].count;
					cc = reports[h].centers.length;
				}
				this.reports.push({
					time: h,
					rc,
					cc,
				})
			}

			for(let i in reports){
				this.reports
			}

			this.doctors = this.doctors.sort(d=>d.online?-1:1);

		},
	},
}
</script>